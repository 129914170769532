<template>
    <v-container v-resize="onResize">

        <h1 class="subheading grey--text text-center mb-15">{{i18n.Welcome[state.lang]}}</h1>   

        <v-carousel show-arrows-on-hover  cycle interval="6000" hide-delimiters hide-delimiter-background v-model="model" height="100%">
            <v-carousel-item v-for="i in layout[$vuetify.breakpoint.name].length" :key="i" height="100%" align="center" justify="center">
                <v-row  align="center" justify="center"  class="mx-4 pa-5" style="background-color: #fafafa">
                    <v-col
                        align="center"
                        justify="center"

                        v-for="n in layout[$vuetify.breakpoint.name][i-1][0] /* forN *//* imgs.length */"
                        :key="n"
                        class="d-flex child-flex"
                        :cols="12 / layout[$vuetify.breakpoint.name][i-1][0] * layout[$vuetify.breakpoint.name][i-1][1]"
                        >

                        <v-hover v-slot="{ hover }">
                            

                        <v-img
                            align="center"
                            justify="center"

                            
                            :src="require(`@/assets/collage3_${((300001 * n * (i+1)) + 3 )% 17}-min.jpg`)"
                            
                            :lazy-src="`https://picsum.photos/10/6?image=${(layout[$vuetify.breakpoint.name][i-1][2]+n-1) % 96  /* ((n + 10) * i + 10) % 96 */}`"
                            aspect-ratio="1"
                            class="grey lighten-2"
                        >
                            <template v-slot:placeholder>
                                <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-circular
                                    align="center"
                                    justify="center"

                                    indeterminate
                                    color="grey lighten-5"
                                    ></v-progress-circular>
                                </v-row>
                            </template>

                            <v-overlay absolute :value="hover">
                                <div>{{""}}</div>
                            </v-overlay>
                        </v-img>

                        </v-hover>


                    </v-col>
                </v-row>
            </v-carousel-item>
        </v-carousel>

        
    </v-container>

</template>

<script>
/* import Carousel from '@/components/CarouselHeaderComponent.vue' 
:src="require(`@/assets/collage_${(1000 + n) % 18}.jpg`)"
:src="`https://picsum.photos/500/300?image=${(layout[$vuetify.breakpoint.name][i-1][2]+n-1) % 96  / * ((n + 10) * i + 10) % 96 * /}`"
*/
import { useStateStore } from '@/store/state.js'

export default {

    data() {
        return {
            state: useStateStore(),


            forN: 4,
            cols: 6,
            
            layout: {
                'xl': [[2, 1,  0], [8, 2,  4], [18, 3,  0]/* , [72, 6,  7], */  /**/],    /* 2:1 */
                'lg': [[6, 2,  0], [24, 4, 0]/* , [96, 8,  0], */ ],  /* 1,5:1 */          /* [8, 2,  4], [8, 2,  6]   [12, 3,  15]*/         /* https://www.desmos.com/calculator/824d34m5wf */
                'md': [[12, 3, 0]/* , [108, 9, 0] */],  /* 1,3333 : 1 */
                'sm': [[3, 1,  0], [12,  2, 0]/* , [48, 4, 0] */],   /* 3:1 */
                'xs': [[1,1,0], [4,2,0]]   /* [[2, 2, 0], [8, 4, 0], [18, 6, 0], [32, 8, 0], [72, 12, 0], [288, 24, 0]], */ /* 1:2 */                      /* [[30, 5, 0], [120, 10, 0]]  */ /* 1,2 : 1 */                    /* [20, 5, 0], [180, 15, 0]    0.8:1 */       /*   [60, 5, 0]     2,4:1   */      /* [[15, 5, 0], [60, 10, 0]]  0.6:1 */         /* [[10, 5, 0], [40, 10, 0]]   0.4:1 */
            },

            imgs: [],


            model: true,
            /* content:  [
                { text: "111111111111111111111111111", img: "https://bechtel.se/img/header.c6917471.jpg" },
                { text: "222222222222222222222222222", img: "https://bechtel.se/img/header.c6917471.jpg" },
                { text: "333333333333333333333333333", img: "https://bechtel.se/img/header.c6917471.jpg" }
            ], */

            i18n: {
                "Welcome": {
                    'sv': "Välkommen till Vingården Bechtel",
                    'de': "Willkommen zum Weingut Bechtel",
                    'eu': "Welcome to Bechtel"
                }
            }
        }
    },

    methods: {
        onResize() {

            var getForN = () => {

                var bp = this.$vuetify.breakpoint

                if(bp.xl) return 1

                else return 3
            }

            var getCols = () => {

                return 4
            }

            this.forN = getForN()
            this.cols = getCols()

            /* console.log("this.$vuetify.breakpoint: ", this.$vuetify.breakpoint)
            console.log("this.$vuetify.xl: ", this.$vuetify.breakpoint.xl) */
        },

        random_img_num() {

            return (Math.floor(Math.random() * 101) % 17)
        }
    },

    components: { /* Carousel */ },

    computed: {
    },

    mounted() {

        for(var i = 1; i <= 25; i++) this.imgs.push(`https://picsum.photos/500/300?image=${i * 1 + 10}`)
    }
}
</script>