<template>
    <!-- <span> -->
        
        <span v-if="lang"> {{currency}} {{ (parseInt(price)/100).toLocaleString(undefined, {minimumFractionDigits: state.getLang(lang).minimumFractionDigits}) }} {{state.getLang(lang).currency}} </span>
        <span v-else        > {{ (parseInt(price)/100).toLocaleString(undefined, {minimumFractionDigits: state.getLang().minimumFractionDigits}) }} {{state.getLang().currency}} </span>
        
    <!-- </span> -->
</template>

<script>
import { useStateStore } from '@/store/state.js'

export default {

    data() {
        return {
            state: useStateStore(),
            /* p: 99999 */
        }
    },

    /* props: [ price ], */

    props: {
        price: { 
            type: Number, 
            default: 999999,
        },
        lang: {
            type: String,
            default: null,
        },
    },

    methods: {
    },

    mounted() {

        console.log("currency: ", this.currency)
    }
}
</script>