<template>
    <span>{{ round ? (ml >= 1000 ? `~${ ((Math.round((ml/100)))/10).toLocaleString(undefined, {minimumFractionDigits: 0})} L` : `${Math.round(ml)} ml`) : (ml >= 1000 ? `${( ml/1000).toLocaleString(undefined, {minimumFractionDigits: 0})} L` : `${ml} ml`)}}</span>
</template>

<script>
export default {
    
    props: {
        ml: { type: Number, default: 99999},
        round: { type: Boolean, default: false}
    }
}
</script>