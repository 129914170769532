<template>

  <v-hover v-slot="{ hover }">
    <v-dialog transition="dialog-bottom-transition" max-width="600">

      <template v-slot:activator="{on, attrs }">

        <v-card :loading="loading"  max-width="374" outlined :class="`elevation-${hover ? 2 : 0} `"   v-bind="attrs" v-on="on">

          <template slot="progress">
            <v-progress-linear color="deep-purple" height="10" indeterminate />
          </template>

          <!-- <v-img height="250" src="https://bechtel.se/img/sommarang.3cf59779.jpg" /> -->
          <!-- <Carousel v-bind="product.img" /> -->
          <div v-if="product._imgs">
            <v-carousel cycle interval="10000" hide-delimiter-background show-arrows-on-hover>
              <!-- <v-carousel-item  v-for="(img, i) in product.imgs" :key="i" :src="img" /> -->
              <v-carousel-item  v-for="(img, i) in product._imgs" :key="i" :src="require(`@/assets/${img}`)" />
            </v-carousel>
          </div>
          <div v-else>
            <v-carousel hide-delimiters :show-arrows="false">
              <v-carousel-item :src="'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fgetdrawings.com%2Fimg%2Fwine-bottle-and-glass-silhouette-4.jpg&f=1&nofb=1'" />
            </v-carousel>
          </div>

          <v-card-title>
            <span>{{product._i18n.sv.name}} </span>
          </v-card-title>
          

          <v-card-text>
            <!-- <v-row align="center" class="mx-0">

              <v-rating :value="4.5" color="amber" dense half-increments readonly size="14" />

              <div class="grey--text ms-4">
                4.5 (413)
              </div>
            </v-row> -->

            <!-- <div class="my-4 text-subtitle-1">
              $ • Italian, Cafe
            </div> -->

            <div> {{product._i18n.sv.text}} </div>
          </v-card-text>

          <v-divider class="mx-4" />

          <!-- <v-card-title>Tonight's availability</v-card-title> -->

          <v-card-text>
            <v-chip-group column>
              <!-- <v-chip>{{ (product._i18n.sv.price/100).toLocaleString(undefined, { minimumFractionDigits: 0}) }} kr</v-chip> -->
              <v-chip><Price :price="product._i18n[state.lang].price" /></v-chip>
              <!-- <v-chip>{{ (product.perc/100).toLocaleString(undefined, {minimumFractionDigits: 0}) }} %</v-chip> -->
              <v-chip><Percent :percent="product.perc" /></v-chip>
              <!-- <v-chip>{{ product.ml >= 1000 ? `${(product.ml/1000).toLocaleString(undefined, {minimumFractionDigits: 0})} L` : `${product.ml} ml` }}</v-chip> -->
              <v-chip><Ml :ml="product.ml" /></v-chip>
              <v-chip>{{product.qty}}{{product.qty >= 99 ? "+" : ""}} st</v-chip>
              <!-- expand -->
              <v-chip v-if="!hideExpandChips" @click="expandChips = !expandChips">
                <v-icon v-if="!expandChips">mdi-chevron-down</v-icon>
                <v-icon v-else            >mdi-chevron-up </v-icon>
              </v-chip> 

              <v-chip v-if="expandChips || hideExpandChips">{{product.type}}</v-chip>
              <v-chip v-if="expandChips || hideExpandChips">{{product.temp}} °C</v-chip>
              <v-chip v-if="expandChips || hideExpandChips">{{product.year}}</v-chip>
            </v-chip-group>
          </v-card-text>

          <!-- <v-card-text class="my-0 py-0">
            <span>+ alk skat & frakt blablabla</span>
          </v-card-text> -->

          <!-- <v-card-actions class="mt-0 pt-0">

            <v-btn-toggle dense rounded    >

              <v-btn v-if="amount > 1" @click="increaseAmount(-1)">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <v-btn v-else disabled>
                <v-icon>mdi-minus</v-icon>
              </v-btn>

              <v-btn depressed @click="increaseAmountJump()" >
                <span>{{amount}}</span>
              </v-btn>

              <v-btn @click="increaseAmount(1)">
                <v-icon>mdi-plus</v-icon>
              </v-btn >

            </v-btn-toggle>

            <v-spacer />

            < !-- <v-hover v-slot="{ hover }"> -- >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" @click="shop.addToBasket(product, amount) ; snackbar = true" large text class="rounded-pill">
                    <v-icon large dense>mdi-basket-check-outline</v-icon> < !-- {{i18n.AddTo[state.lang]}} -- > < !-- <v-icon>mdi-basket-plus</v-icon> <v-icon>mdi-basket-plus-outline</v-icon> -- >
                  </v-btn>
                </template>
                <span>{{i18n.AddTo[state.lang]}}</span>
              </v-tooltip>

              <v-snackbar  height="0" width="auto"  class="mt-16 pa-0" right top v-model="snackbar" color="rgba(0,0,0,0)" elevation="0" timeout="1200">
                      <v-alert  dense elevation="5" border="left" type="info" dismissible @click="snackbar = false" >
                          {{amount}} {{amount > 1 ? i18n.Flasks[state.lang] : i18n.Flask[state.lang]}} <code>{{product._i18n.sv.name}}</code> {{i18n.Added[state.lang]}}
                      </v-alert>               
              </v-snackbar>
              
            < !-- </v-hover> -- >
            
          </v-card-actions> -->
        </v-card>
    
      </template>




      <template v-slot:default="dialog">

        <v-card>
          <v-toolbar
            color="primary"
            dark
            class="text-h4"
          >{{product._i18n[state.lang].name}}</v-toolbar>
          <v-card-text>
            <div class="text-h5 pa-12">{{product._i18n[state.lang].description2}}</div>
          </v-card-text>


          <v-simple-table>
          <template>
            <tbody>
              <tr>
                <td class="text-center text-uppercase">{{i18n.Designation[state.lang]}}</td>
                <td class="text-center"><!-- <h3> -->{{product.year}} {{product.type}} {{product._i18n[state.lang].description}}<!-- </h3> --></td>
              </tr>
              <tr>
                <td class="text-center text-uppercase">{{i18n.QltyLv[state.lang]}}</td>
                <td class="text-center">{{product._i18n[state.lang].qlty}}</td>
              </tr>
              <tr>
                <td class="text-center text-uppercase">{{i18n.WrhsOrg[state.lang]}}</td>
                <td class="text-center">{{product.origin}}</td>
              </tr>
              <tr>
                <td class="text-center text-uppercase">{{i18n.Contents[state.lang]}}</td>
                <td class="text-center">{{product.ml}} ml</td>
              </tr>
              <tr>
                <td class="text-center text-uppercase">{{i18n.AlcCont[state.lang]}}</td>
                <td class="text-center">{{ (product.perc/100).toLocaleString(undefined, {minimumFractionDigits: 0}) }} %</td>
              </tr>
              <tr>
                <td class="text-center text-uppercase">{{i18n.Sug[state.lang]}}</td>
                <td class="text-center">{{ (product.sug/100).toLocaleString(undefined, {minimumFractionDigits: 1}) }} g/ltr</td>
              </tr>
              <tr>
                <td class="text-center text-uppercase">{{i18n.Acid[state.lang]}}</td>
                <td class="text-center">{{ (product.acid/100).toLocaleString(undefined, {minimumFractionDigits: 1}) }} g/ltr</td>
              </tr>
              <tr>
                <td class="text-center text-uppercase">{{i18n.Temp[state.lang]}}</td>
                <td class="text-center">{{product.temp}} °C</td>
              </tr>
              <!-- <tr>
                <td class="text-center text-uppercase">{{i18n.Recom[state.lang]}}</td>
                <td class="text-center">{{product._i18n[state.lang]text}}</td>
              </tr> -->
              <tr>
                <td class="text-center text-uppercase">{{i18n.Tast[state.lang]}}</td>
                <td class="text-center">{{product._i18n[state.lang].degus}}</td> 
              </tr><!-- -->
            </tbody>
          </template>
        </v-simple-table>



          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="dialog.value = false"
            >{{i18n.close[state.lang]}}</v-btn>
          </v-card-actions>
        </v-card>

        

      </template>


    </v-dialog>
  </v-hover>
</template>


<script>
/* import Carousel from '@/components/CarouselHeaderComponent.vue' */
import { useShopStore } from '@/store/shop.js'
import { useStateStore } from '@/store/state.js'
import Price from '@/components/tag/PriceTag.vue'
import Percent from '@/components/tag/PercentTag.vue'
import Ml from '@/components/tag/MlTag.vue'

export default {
  name: "Product",  

  data() {
    return {
      loading: false,
      selection: 1,
      amount: 1,
      state: useStateStore(),
      shop: useShopStore(),
      snackbar: false,
      expandChips: false,
      hideExpandChips: true,

      i18n: {
        "close": {
          'sv': "close",
          'de': "close",
          'eu': "close",
        },
        "AddTo": {
          'sv': "Lägg i Varukorg",
          'de': "In den Warenkorb Legen",
          'eu': "Add to Cart",
        },

        "Added": {
          'sv': "lades till i varukorgen",
          'de': "wurde dem Warenkorb hinzugefügt",
          'eu': "was added to basket",
        },

        "Flask": {
          'sv': "flaska",
          'de': "Flasche",
          'eu': "flask",
        },

        "Flasks": {
          'sv': "flaskor",
          'de': "Flaschen",
          'eu': "flasks",
        },
        "Designation": {
            'sv': "Beteckning",
            'de': "Bezeichnung",
            'eu': "Designation",
        },
        "QltyLv": {
            'sv': "Kvalitetsnivå",
            'de': "Qualitätsstufe",
            'eu': "Quality Level",
        },
        "WrhsOrg": {
            'sv': "Lager/Ursprung",
            'de': "Lager/Herkunft",
            'eu': "Warehouse/Origin",
        },
        "Contents": {
            'sv': "Innehåll",
            'de': "Inhalt",
            'eu': "Contain",
        },
        "AlcCont": {
            'sv': "Alkoholhalt",
            'de': "Alkoholgehalt", 
            'eu': "Alcohol Content",
        },
        "Sug": {
            'sv': "Restsocker",    
            'de': "Restzucker",
            'eu': "Residual Sugar",
        },
        "Acid": {
            'sv': "Syra",
            'de': "Säure",
            'eu': "Acid",
        },
        "Temp": {
            'sv': "Drickstemperatur",
            'de': "Trinktemperatur",
            'eu': "Drinking Temperature",
        },
        "Recom": {
            'sv': "Dricksrekommendation",
            'de': "Trinkempfehlung",
            'eu': "Drinking Recommendation",
        },
        "Tast": {
            'sv': "Smaknotis",
            'de': "Degustation Notiz",
            'eu': "Tasting Note"
        },
      }
    }
  },

  props: {
    product: Object
  },
  /* props: [ product ], */

  methods: { 

    increaseAmount(amount)  {

      this.amount += amount
      if(this.amount < 1) this.amount = 1
    },

    increaseAmountJump() {

      if(this.amount == 1 || (this.amount % 6) == 0 ) this.amount += (6 - this.amount % 6)
      else if((this.amount % 6) > 0)  this.amount = 1
    },
  },

  components: { Price, Percent, Ml /* Carousel */ },

  mounted() {}
}
</script>