<template>
    <v-container class="d-flex justify-center">
        <!-- <v-card width="50%">
            <v-img max-height="200" src="https://bechtel.se/img/sommarang.3cf59779.jpg" />
            <v-card-text>
                hi?
            </v-card-text>
        </v-card> -->
        <blog-card :blog="blog" />
    </v-container>
</template>

<script>
import BlogCard from '@/components/BlogCardComponent.vue'

export default {

    data() {
        return {
            blog: null
        }
    },

    props: {
        id: String
    },

    components: {  BlogCard },

    mounted() {
        fetch(`${process.env.NODE_ENV === 'development' ?  process.env.VUE_APP_DEV_SERVER_URL : ''}/api/blogs/${this.id}`)
            .then(res  => res.json())
            .then(data => this.blog = data)
            .catch(err => console.log("API ERROR (BlogFeature): ", err.message))
    }
}
</script>