<template>
    <span>{{ (percent/100).toLocaleString(undefined, {minimumFractionDigits: 0}) }} %</span>
</template>

<script>
export default {
    props: {
        percent: { type: Number, default: 999999}
    }
}
</script>