<template>
    
    <v-container>
        

        <h1 class="subheading grey--text text-center">Oliver Bechtel</h1>   <!-- Make own component and style it a bit -->

        <v-container class="grey lighten-5 mt-4 py-8">
            <v-row>
                <v-col :cols="resizer[$vuetify.breakpoint.name].col1.cols"  align="center" justify="center"  >
                    <v-img :src="require(`@/assets/collage2_0.jpg`)" alt=""/>
                </v-col>


                <!--  -->


                <v-col :cols="resizer[$vuetify.breakpoint.name].col2.cols" align-self="center" :height="resizer[$vuetify.breakpoint.name].col2.height">

                    <v-img :height="resizer[$vuetify.breakpoint.name].col2.height"  :src="require(`@/assets/collage3_16.jpg`)" alt="" >
                        <v-carousel :height="resizer[$vuetify.breakpoint.name].col2.height" cycle :interval="5 * 1000" class="mb-16 pb-16" :show-arrows="false" :hide-delimiter-background="true"  delimiter-icon="mdi-circle-small " >
                            <v-carousel-item>
                                <v-overlay absolute class="text-center" >
                                    <div class="mx-16">
                                        <v-text :class="'text-' + resizer[$vuetify.breakpoint.name].col2.text">
                                            Oliver Bechtel, född 2002 i Bryssel, svensk mamma, tysk pappa, uppvuxen i Stockholm, nu i Tyskland för att plugga till vinbonde. Vin har odlats i familjen sedan många generationer och efter att en generation hoppade över så var vingårdens framtid något oviss. Farmor och farfar jobbade på, de senaste åren med enbart druvproduktionen och hoppades att något av barnbarnen skulle vilja fortsätta. Nu är jag här och tillsammans sköter vi om vingården som har ca. 60 000 vinstockar med en mångfald av olika druvsorter. Sakta men säkert kommer vi att börja bygga upp vinsortimentet igen och hoppas att om något år kunna erbjuda flera olika sorters vin.
                                        </v-text>
                                        <!-- <span class="headline"><h3>{{product._i18n[state.lang].text}}</h3></span>  -->
                                    </div>
                                </v-overlay>
                            </v-carousel-item>
                            <!-- <v-carousel-item>
                                <v-overlay absolute class="text-center" >
                                    <div class="mx-16">
                                        <span class="headline">"{{ resizer[$vuetify.breakpoint.name].col2 }}"</span> 
                                    </div>
                                </v-overlay>
                            </v-carousel-item>
                            <v-carousel-item>
                                <v-overlay absolute class="text-center" >
                                    <div class="mx-16">
                                        <span class="headline">"{{product._i18n[state.lang].text}}"</span> 
                                    </div>
                                </v-overlay>
                            </v-carousel-item> -->
                        </v-carousel>
                    </v-img>

                    <!-- <v-card centered>
                        <v-card-text class="text-subtitle-2 text--primary">
                            
                            Oliver Bechtel, född 2002 i Bryssel, svensk mamma, tysk pappa, uppvuxen i Stockholm, nu i Tyskland för att plugga till vinbonde. Vin har odlats i familjen sedan många generationer och efter att en generation hoppade över så var vingårdens framtid något oviss. Farmor och farfar jobbade på, de senaste åren med enbart druvproduktionen och hoppades att något av barnbarnen skulle vilja fortsätta. Nu är jag här och tillsammans sköter vi om vingården som har ca. 60 000 vinstockar med en mångfald av olika druvsorter. Sakta men säkert kommer vi att börja bygga upp vinsortimentet igen och hoppas att om något år kunna erbjuda flera olika sorters vin.
                    
                        </v-card-text>
                    </v-card> -->
                    
                </v-col>


            </v-row>
        </v-container>


    </v-container>




    




</template>

<script>
// import Product from '@/components/ProductComponent.vue'
// import BlogFeature from '@/components/BlogFeatureComponent.vue'
import { useStateStore } from '@/store/state.js'

export default {

    data() {
        return {
            i18n: {
                "": {
                    'sv': "",
                    'de': "",
                    'eu': "",
                }
            },
            resizer: {
                'xl': {
                    margin: true,
                    col1: { cols: 6},
                    col2: { cols: 6, contain: true, height: "1070px", text: "h4" },
                },
                'lg': {
                    margin: false,
                    col1: { cols: 6},
                    col2: { cols: 6, contain: false, height: "695px", text: "subtitle-1" },
                },
                'md': {
                    margin: false,
                    col1: { cols: 6},
                    col2: { cols: 6, contain: false, height: "517px", text: "body-2"},
                },
                'sm': {
                    margin: false,
                    col1: { cols: 12},
                    col2: { cols: 12, contain: false, height: "517px", text: "subtitle-1" },
                },
                'xs': {
                    margin: false,
                    col1: { cols: 12},
                    col2: { cols: 12, contain: false, height: "520px", text: "caption" },
                },
            },
        }
    },

    methods: {

        onResize() {

        }
    },

    components: { /*Product, BlogFeature*/ }
}
</script>