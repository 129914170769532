<template>
    <div v-if="true">
        <v-carousel cycle  progress  hide-delimiter-background show-arrows-on-hover>
            <v-carousel-item v-for="(img, i) in imgs" :key="i" :src="img" />
        </v-carousel>
    </div>
    <v-carousel v-else cycle  hide-delimiter-background show-arrows-on-hover>
        <v-carousel-item v-for="(img, i) in imgs" :key="i">
            <!-- <v-img /> -->
            <v-parallax :src="img" ></v-parallax>
        </v-carousel-item>
    </v-carousel>
</template>

<script>
export default {
    data() {
        return { }
    },

    props: {
        imgs: {
            type: Array, 
            default() { return ['https://bechtel.se/img/sommarang.3cf59779.jpg', 'https://bechtel.se/img/sommarang.3cf59779.jpg']} 
        }
    }
}
</script>