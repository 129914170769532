<template>
    <div class="text-center">
        <v-dialog persistent v-model="dialog" max-width="800">
 
 
            <v-card >

                <v-card-title class="text-h5 grey ma-0 pa-0 lighten-2" @click="e1 = 1">
                    <!-- <v-icon large color="grey">mdi-flag</v-icon> -->
                    <v-breadcrumbs large :items="items" >
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                        <!-- <v-icon>mdi-flag</v-icon> -->
                    </v-breadcrumbs>
                </v-card-title>

                

                <v-card-text>
                    
                    <!-- <v-card
                        class="d-flex justify-space-around my-6"
                        flat tile
                        > -->

                        <v-stepper elevation="0"  v-model="e1">

                            <v-stepper-items>

                                <v-stepper-content step="1">
                                    <v-layout row wrap>
                                        <!-- <v-flex v-for="(lang, i) in langs" :key="i"> -->
                                        <v-flex v-for="(lang, i) in state.langOpt" :key="i">
                                            <v-hover v-slot="{ hover }">

                                                <v-card
                                                    @click="selectLang(lang.opt)"
                                                    class="pa-2 px-5 ma-5 text-center"
                                                    :elevation="hover ? 3 : 1"
                                                    >
                                                    <div>
                                                        <img :src="lang.icon" height="100" alt="" />
                                                    </div> 
                                                    <div>{{lang.text}}</div>
                                                    <div class="grey--text text--darken-1">{{lang.i18n}}</div>
                                                </v-card>

                                            </v-hover>
                                        </v-flex>
                                    </v-layout> 
                                </v-stepper-content>

                                <v-stepper-content step="2">

                                    
                                        <h1 class="text-center headline">{{i18n.AgeQuestion[state.lang]}}</h1>


                                        <v-layout column class="mt-7">
                                            <v-flex>
                                                <v-hover v-slot="{ hover }">
                                                    <v-btn 
                                                        @click="verifyAge(true)"
                                                        rounded block x-large  
                                                        :elevation="hover ? 2 : 1" 
                                                        :class="`my-2 ${hover ? 'green--text' : ''}   `" 
                                                        :color="hover ? '#fefefe' : '#fff' "
                                                        >
                                                        {{i18n.Yes[state.lang]}}
                                                    </v-btn>
                                                </v-hover>
                                                
                                            </v-flex>
                                            <v-flex>
                                                <v-btn
                                                    @click="verifyAge(false)"
                                                    rounded block large  
                                                    
                                                    class="my-1" 
                                                    >
                                                    {{i18n.No[state.lang]}}
                                                </v-btn>       
                                            </v-flex>
                                        </v-layout>
                                            
                                               

                                    
                                    
                                    

                                </v-stepper-content>

                            </v-stepper-items>

                        </v-stepper>

                           
                    <!-- </v-card> -->



                </v-card-text>
            </v-card>

        </v-dialog>
    </div>
</template>

<script>
import { useStateStore } from '@/store/state.js'

export default {

    data() {
        return {
            dialog: false,
            state: useStateStore(),
            e1: 1,
            items: [
                /* { text: <i aria-hidden="true" class="v-icon notranslate mdi mdi-flag theme--light" style="font-size: 28px;"></i>, disabled: false}, */
                { text: <button type="button" class="v-btn v-btn--icon v-btn--round theme--light v-size--default"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-flag theme--light" style="font-size: 28px;"></i></span></button>, disabled: false},
                { text: <i aria-hidden="true" class="v-icon notranslate mdi mdi-alert-octagon-outline  theme--light" style="font-size: 28px;"></i>, disabled: false},
                /* { text: 'age', disabled: true} */
            ],
            /* langs: [
                { icon: require(`@/assets/swedenSq.png`), text: 'Svenska', i18n: 'Sweden', opt: 'sv',    decl: 'https://www.iq.se/tonarsparloren/varfor-ar-alkohol-farligt-for-unga/' },
                { icon: require(`@/assets/germanySq.png`), text: 'Deutsch', i18n: 'Germany', opt: 'de',  decl: 'https://www.kenn-dein-limit.info/' },
                { icon: require(`@/assets/european.png`), text: 'EU', i18n: 'European Union', opt: 'eu', decl: 'https://www.iq.se/tonarsparloren/en/why-is-alcohol-dangerous-for-young-people/' },
            ], */

            i18n: {
                "AgeQuestion": {
                    'sv': "Är du över 20 år?",
                    'de': "Bist du über 18?",
                    'eu': "Are you 18+ ?",
                },

                "Yes": {
                    'sv': "Ja",
                    'de': "Ja",
                    'eu': "Yes",
                },

                "No": {
                    'sv': "Nej",
                    'de': "Nein",
                    'eu': "No",
                }
                
            }
        }
    },

    methods: {

        selectLang(lang='eu') {

            this.state.setLang(lang)
            localStorage.setItem('lang', lang)

            this.e1 = 2
        },

        verifyAge(age=false) {
             
            this.state.setAge(age)
            localStorage.setItem('age', age)

            if(age) this.dialog = false

            /* else redirect */
            else window.location.href = this.state.getLang().decl
        }
    },

    /* props: [ attr ], */
    props: { attr: Boolean },

    mounted() {
        /* console.log(this.$vuetify.icons) */
        /* const state = useStateStore() */
        /* console.log("State: ", state) */
        this.dialog = this.attr
        if(localStorage.lang) this.e1 = 2
    }
}
</script>



<!-- <button type="button" class="v-btn v-btn--icon v-btn--round theme--light v-size--default"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-flag theme--light"></i></span></button>
<button type="button" class="v-btn v-btn--icon v-btn--round theme--light v-size--default"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-flag theme--light"></i></span></button> -->