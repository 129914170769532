<template>
    <div>
        <v-hover v-slot="{ hover }" >
                
            <v-card :elevation="hover ? 4 : 2" max-width="444" router :to="`/blog/${blog._id}`" >
            
                <v-img :src="blog.img" lazy-src="https://cdn.vuetifyjs.com/images/cards/docks.jpg" class="white--text align-end" height="200px">
                    <v-card-title>{{blog.title}}</v-card-title>

                    <v-overlay absolute :value="hover">
                        <!-- <span class="mx-5 justify-center">{{blog.description}}</span> -->
                        <div center class="ma-15 justify-center centered center">"{{blog.description}}..."</div>
                    </v-overlay>
                </v-img>

                <v-card-subtitle>
                    {{format(parseISO(blog.date), "do MMM YYY")}}
                </v-card-subtitle>

                <v-card-text>
                    <div>{{blog.description}}</div>
                    <!-- <div>456</div> -->
                    
                    <v-chip-group>
                        <v-chip v-for="(tag, i) in blog.tags" :key="i">
                            <v-icon>{{tag.icon}}</v-icon>
                            <span>{{tag.text}}</span>
                        </v-chip>
                    </v-chip-group>
                </v-card-text>

                <!-- <v-card-actions>
                    <v-btn>HI</v-btn>
                </v-card-actions> -->
            </v-card>

        </v-hover>
    </div>
</template>

<script>
import format from 'date-fns/format'
import { parseISO } from 'date-fns/esm/fp';

export default {

    data() {
        return {
            format: format,
            parseISO: parseISO,
        }
    },

    props: { 
        blog: Object
    }
}
</script>