import { defineStore } from 'pinia'
import { useStateStore } from '@/store/state.js'

const isObject = (obj) => { return (typeof obj === 'object' && !Array.isArray(obj) && obj !== null) }
const isInt = (int) => { return (int === parseInt(int, 10)) }


export const useShopStore = defineStore('shop', {

    state: () => {
        return {
            basket: [ /* { item: Object, count: 0 } */ ],
            shippingPrice: 99999,
            totalItemPrice: 99999,
            __state: useStateStore(),
        }
    },

    /* getters: {
        getItemG: (state) => { 
            return (index) => state.basket[index]
        }
    }, */

    actions: {

        /* addToBasket(item, amount) {
            //this.basket.push(item)

            if (this.basket.find((i) => { 

                if(i.item._id === item._id) {
                    i.count += amount
                    return true
                }
                else return false
            }))
            return

            else { this.basket.push({item: item, count: amount}) }
        },


        taddToBasket(item, amount) {
            //this.basket.push(item)

            if (this.basket.find((i) => { 

                if(i.item._id === item._id) 
                    return ( i.count += amount)
                else 
                    return false
            }))
            return

            else { this.basket.push({item: item, count: amount}) }
        }, */

        updateShipping() {          /* Move to serverside calculation ?? */

            var totalItems = this.totalItems()
            var price = 0

            switch (this.__state.lang) {

                case 'sv':
                    
                    price = totalItems * 2000
                    break


                case 'de':
                    /* price += Math.floor(totalItems / 12) * 970 */
                    totalItems -= Math.floor(totalItems / 12) * 12

                    /* price += Math.floor(totalItems / 6) * 590 */
                    totalItems -= Math.floor(totalItems / 6) * 6

                    price += Math.ceil(totalItems / 6) * 590
                    break

            }
            


            this.shippingPrice = price
        },

        addToBasket(item, amount) {

            if (this.basket.find((i) => { 
                if(i.item._id === item._id) 
                    return !!((i.count += amount) + (i.total_price = i.count * i._i18n[this.__state.lang].price))
            })) ""

            else { this.basket.push({item: item, count: amount, total_price: (item._i18n[this.__state.lang].price * amount) }) }
        },

        removeFromBasket(index) {

            if(isObject(index)) index = this.basket.indexOf(index)

            this.basket.splice(index, 1)
        },

        getItem(index) {

            return this.basket[index]
        },
        
        getPrice(item) {

            if(!isObject(item))
            if(isInt(item) && item < this.basket.length) 
                item = this.basket[item]

            return item.item._i18n[this.__state.lang].price * item.count /* if currency dosent exist handel it maby?! */
        },

        increase(item, amount) {

            if(!isObject(item))
            if(isInt(item) && item < this.basket.length)
                item = this.getItem(item) 

            item.count += amount
            if(item.count < 0) item.count = 0


            console.log("increase: ", item)


            this.updateTotalPrice(item)
            this.updateShipping()
            this.updateTotalItemPrice()
            /* this.updateAllCurrencys() */

            console.log("increas2: ", item)
        },

        totalItems() {
            
            var temp = 0
            this.basket.forEach((i) => {
                temp += i.count
            })
            
            return temp.toString()
        },

        updateTotalItemPrice() {

            var price = 0
            this.basket.forEach((i) => {
                price += i.total_price
            })

            this.totalItemPrice = price
        },

        totalPrice() {
        

            var temp = 0
            this.basket.forEach((item) => {
                temp += this.getPrice(item)
            })
            return temp
        },


        updateTotalPrice(item) {

            console.log("1ncrease: ", item)

            if(!isObject(item))
            if(isInt(item) && item < this.basket.length)
                item = this.getItem(item)

            console.log("2ncrease: ", item)

            item.total_price = (item.item._i18n[this.__state.lang].price * item.count)

            console.log("3ncrease: ", item)
        },

        updateAllCurrencys() {

            this.basket.forEach((item) => {
                item.total_price = (item.item._i18n[this.__state.lang].price * item.count)

                console.log("3ncrease: ", item)
            })
        }
    },
})