<template>
  <div class="home">

    <CarouselHeader :imgs="[require(`@/assets/header.c6917471.jpg`)]" />

    <Divider />

    <!-- <Collage2 /> -->
    <Collage3 />

    <!-- <Collage id="scroll_collage" />-->

    <Divider />

    <Exhibition3 />
    
    <!-- <Exhibition2 id="scroll_exhibition" productid="62fc0a59de23090007c9ba96" /> -->

    <Divider />

    <!-- <Exhibition id="scroll_exhibition" productid="62fc0a59de23090007c9ba96" />
    
    <Divider /> -->
    
    <AllProduct id="scroll_allProduct" />

    <Divider />

  </div>
</template>

<script>
  import CarouselHeader from '@/components/CarouselHeaderComponent.vue'
  /* import Exhibition  from '@/components/ExhibitionComponent.vue' */
  import Exhibition3 from '@/components/Exhibition3Component.vue'
  import Exhibition2 from '@/components/Exhibition2Component.vue'
  import AllProduct  from '@/components/AllProductsComponent.vue'
  import Divider from '@/components/DividerComponent.vue'
  /* import Collage from '@/components/CollageComponent.vue' */
  // import Collage2 from '@/components/Collage2Component.vue'
  import Collage3 from '@/components/Collage3Component.vue'


  export default {
    name: 'HomeView',

    data() {
      return {
        
      }
    },

    mounted() {
      
    },  

    components: {  CarouselHeader, AllProduct, /**/ Exhibition2,  Exhibition3, Divider, /* Collage,  Collage2*/ Collage3 },
  }
</script>
