<template>
    <div class="mt-4 pt-4">
        <h1 class="subheading grey--text text-center">Sortiment</h1>

        <v-container class="">
          <v-layout row wrap class="">
              <v-flex xs12 sm6 md4 lg3 class="pa-1"  v-for="(product, i) in products" :key="i">
                  <Product v-bind:product="product" class="mx-auto my-12" />
              </v-flex>
          </v-layout>
        </v-container>
    </div>
</template>

<script>
import Product from '@/components/ProductComponent.vue'

export default {
    data() {
        return {
            products: {}
        }
    },

    mounted() {
        //console.log("MOUNTED (process) : ", process.env)
        /* fetch('/api/products/') */
        fetch(`${process.env.NODE_ENV === 'development' ?  process.env.VUE_APP_DEV_SERVER_URL : ''}/api/productsmongo/`)
            .then(res  => res.json())
            .then(data => this.products = data)
            .catch(err => console.log("API ERROR: ", err.message))
    },
    components: { Product }
}
</script>




<!-- 
        <v-flex xs12 sm6 md4 lg3 class="pa-1">
          <Product />
        </v-flex>
        <v-flex xs12 sm6 md4 lg3 class="pa-1">
          <Product />
        </v-flex>
        <v-flex xs12 sm6 md4 lg3 class="pa-1">
          <Product />
        </v-flex>
        <v-flex xs12 sm6 md4 lg3 class="pa-1">
          <Product />
        </v-flex> -->