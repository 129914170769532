<template>
    
    <v-container>
        

        <h1 class="subheading grey--text text-center">{{product._i18n[state.lang].name}}</h1>   <!-- Make own component and style it a bit -->

        <v-container class="grey lighten-5 mt-4 py-8">
            <v-row>
                <v-col :cols="resizer[$vuetify.breakpoint.name].col1.cols"  align="center" justify="center"  >
                    <Product :product="product" align="start"
                            justify="start" />
                    <!-- <BlogFeature v-if="false" class="mr-16" id="62fbbb96de23090007c9ba8e" /> -->
                </v-col>

                <v-col :cols="resizer[$vuetify.breakpoint.name].col2.cols"    ><!-- style="outline: 3px solid red " -->
                    <!-- <v-img :src="product._imgs[0]" alt=""  /> -->

                    
                    <!-- <v-carousel
                    :continuous="true"
                    cycle
                    :interval="10 * 1000"
                    :show-arrows="false"
                    :hide-delimiter-background="true"

                    class="rounded-lg"
                    

                    delimiter-icon="mdi-minus"
                    height="800"
                    >
                        <v-carousel-item reverse-transition="fade-transition" transition="fade-transition">
                            <v-img   :src="'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.dXPRbuaR2vgra35I93BOJwHaHK%26pid%3DApi&f=1&ipt=33ae010ee1736bc9eff3ddee0fea6baae7778c1dc9ddb321c24b16c32c81b931&ipo=images'" height="100%" alt=""  :contain="resizer[$vuetify.breakpoint.name].col2.contain" />
                        </v-carousel-item> -->

                        <!-- <v-carousel-item reverse-transition="fade-transition" transition="fade-transition"> -->
                            <div :class="`${resizer[$vuetify.breakpoint.name].margin ? 'mx-16' : ''}`">
                                <div :class="`${resizer[$vuetify.breakpoint.name].margin ? 'mx-16' : ''}`">
                                    <v-card :class="`${resizer[$vuetify.breakpoint.name].margin ? 'mx-12' : ''}`">



        <!-- <v-carousel class="ma-0 pa-0">
            <v-carousel-item>
                <v-img height="365px"  :src="product._imgs[0]" alt="" >
                    <v-overlay absolute class="text-center" >
                        <div class="mx-16">
                            <span class="headline">"{{product._i18n[state.lang].text}}"</span> 
                        </div>
                    </v-overlay>
                </v-img>
            </v-carousel-item>
            <v-carousel-item>
                <v-img height="365px"  :src="product._imgs[0]" alt="" >
                    <v-overlay absolute class="text-center" >
                        <div class="mx-16">
                            <span class="headline">"{{product._i18n[state.lang].text}}"</span> 
                        </div>
                    </v-overlay>
                </v-img>
            </v-carousel-item>
            <v-carousel-item>
                <v-img height="365px"  :src="product._imgs[0]" alt="" >
                    <v-overlay absolute class="text-center" >
                        <div class="mx-16">
                            <span class="headline">"{{product._i18n[state.lang].text}}"</span> 
                        </div>
                    </v-overlay>
                </v-img>
            </v-carousel-item>
        </v-carousel> -->


                                        <!-- <v-img height="365px"  :src="product._imgs[0]" alt="" > -->
                                        <!-- <v-img height="365px"  :src="'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.dXPRbuaR2vgra35I93BOJwHaHK%26pid%3DApi&f=1&ipt=33ae010ee1736bc9eff3ddee0fea6baae7778c1dc9ddb321c24b16c32c81b931&ipo=images'" alt="" > -->
                                        <v-img height="365px"  :src="require(`@/assets/collage3_16.jpg`)" alt="" >
                                            <v-carousel height="365" cycle :interval="5 * 1000" class="mb-16 pb-16" :show-arrows="false" :hide-delimiter-background="true"  delimiter-icon="mdi-circle-small " >
                                                <v-carousel-item>
                                                    <v-overlay absolute class="text-center" >
                                                        <div class="mx-16">
                                                            <span class="headline"><h3>{{product._i18n[state.lang].text}}</h3></span> 
                                                        </div>
                                                    </v-overlay>
                                                </v-carousel-item>
                                                <!-- <v-carousel-item>
                                                    <v-overlay absolute class="text-center" >
                                                        <div class="mx-16">
                                                            <span class="headline">"{{product._i18n[state.lang].text}}"</span> 
                                                        </div>
                                                    </v-overlay>
                                                </v-carousel-item>
                                                <v-carousel-item>
                                                    <v-overlay absolute class="text-center" >
                                                        <div class="mx-16">
                                                            <span class="headline">"{{product._i18n[state.lang].text}}"</span> 
                                                        </div>
                                                    </v-overlay>
                                                </v-carousel-item> -->
                                            </v-carousel>
                                        </v-img>

                                        

        <!-- <v-img height="365px"  :src="product._imgs[0]" alt="" >

            <v-overlay absolute class="text-center" >
                <div class="mx-16">
                    <span class="headline">"{{product._i18n[state.lang].text}}"</span> 
                </div>
            </v-overlay>

        </v-img> -->
                                                

                                                    
                                        <v-simple-table>
                                            <template>
                                                <tbody>
                                                    <tr>
                                                        <td class="text-center text-uppercase">{{i18n.Designation[state.lang]}}</td>
                                                        <td class="text-center"><!-- <h3> -->{{product.year}} {{product.type}} {{product._i18n[state.lang].description}}<!-- </h3> --></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-center text-uppercase">{{i18n.QltyLv[state.lang]}}</td>
                                                        <td class="text-center">{{product._i18n[state.lang].qlty}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-center text-uppercase">{{i18n.WrhsOrg[state.lang]}}</td>
                                                        <td class="text-center">{{product.origin}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-center text-uppercase">{{i18n.Contents[state.lang]}}</td>
                                                        <td class="text-center">{{product.ml}} ml</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-center text-uppercase">{{i18n.AlcCont[state.lang]}}</td>
                                                        <td class="text-center">{{ (product.perc/100).toLocaleString(undefined, {minimumFractionDigits: 0}) }} %</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-center text-uppercase">{{i18n.Sug[state.lang]}}</td>
                                                        <td class="text-center">{{ (product.sug/100).toLocaleString(undefined, {minimumFractionDigits: 1}) }} g/ltr</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-center text-uppercase">{{i18n.Acid[state.lang]}}</td>
                                                        <td class="text-center">{{ (product.acid/100).toLocaleString(undefined, {minimumFractionDigits: 1}) }} g/ltr</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-center text-uppercase">{{i18n.Temp[state.lang]}}</td>
                                                        <td class="text-center">{{product.temp}} °C</td>
                                                    </tr>
                                                    <!-- <tr>
                                                        <td class="text-center text-uppercase">{{i18n.Recom[state.lang]}}</td>
                                                        <td class="text-center">{{product._i18n[state.lang]text}}</td>
                                                    </tr> -->
                                                    <tr>
                                                        <td class="text-center text-uppercase">{{i18n.Tast[state.lang]}}</td>
                                                        <td class="text-center">{{product._i18n[state.lang].degus}}</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                        
                                    </v-card>
                                </div>
                            </div>
                        <!-- </v-carousel-item>

                    </v-carousel> -->
                </v-col>
            </v-row>
        </v-container>
        

    </v-container>
</template>

<script>
import Product from '@/components/ProductComponent.vue'
import BlogFeature from '@/components/BlogFeatureComponent.vue'
import { useStateStore } from '@/store/state.js'

export default {

    data() {
        return {
            product: {
            "id": "62fc0a59de23090007c9ba96",
            "price": 85,
            "year": 2021,
            "type": "Riesling",
            "origin": "Rheinhessen",
            "ml": 750,
            "perc": 1150,
            "sug": 900,
            "acid": 900,
            "temp": "8 - 10",
            "qty": 2000,
            "_imgs": [
                "sommarang.3cf59779.jpg",
            ],
            "_i18n": {
                "sv": {
                    "text": "TEXT",
                    "name": "Sommaräng",
                    "price": 8500,
                    "description": "DESCRIPTION",
                    "qlty": "Tyskt kvalitetsvin",
                    "degus": "DEGUS"
                },
                "de": {
                    "text": "TEXT DE",
                    "name": "Sommaräng",
                    "price": 850,
                    "description": "DESCRIPTION",
                    "qlty": "Deutscher Qualitätswein",
                    "degus": "DEGUS"
                },
                "eu": {
                    "text": "TEXT EU",
                    "name": "Sommaräng",
                    "price": 850,
                    "description": "DESCRIPTION",
                    "qlty": "QLTY",
                    "degus": "DEGUS"
                },
            }
        },
            state: useStateStore(),
            resizer: {
                'xl': {
                    margin: true,
                    col1: { cols: 4},
                    col2: { cols: 8, contain: true},
                },
                'lg': {
                    margin: false,
                    col1: { cols: 4},
                    col2: { cols: 8, contain: false},
                },
                'md': {
                    margin: false,
                    col1: { cols: 5},
                    col2: { cols: 7, contain: false},
                },
                'sm': {
                    margin: false,
                    col1: { cols: 12},
                    col2: { cols: 12, contain: false},
                },
                'xs': {
                    margin: false,
                    col1: { cols: 12},
                    col2: { cols: 12, contain: false},
                }

            },
            i18n: {
                "Designation": {
                    'sv': "Beteckning",
                    'de': "Bezeichnung",
                    'eu': "Designation",
                },
                "QltyLv": {
                    'sv': "Kvalitetsnivå",
                    'de': "Qualitätsstufe",
                    'eu': "Quality Level",
                },
                "WrhsOrg": {
                    'sv': "Lager/Ursprung",
                    'de': "Lager/Herkunft",
                    'eu': "Warehouse/Origin",
                },
                "Contents": {
                    'sv': "Innehåll",
                    'de': "Inhalt",
                    'eu': "Contain",
                },
                "AlcCont": {
                    'sv': "Alkoholhalt",
                    'de': "Alkoholgehalt", 
                    'eu': "Alcohol Content",
                },
                "Sug": {
                    'sv': "Restsocker",    
                    'de': "Restzucker",
                    'eu': "Residual Sugar",
                },
                "Acid": {
                    'sv': "Syra",
                    'de': "Säure",
                    'eu': "Acid",
                },
                "Temp": {
                    'sv': "Drickstemperatur",
                    'de': "Trinktemperatur",
                    'eu': "Drinking Temperature",
                },
                "Recom": {
                    'sv': "Dricksrekommendation",
                    'de': "Trinkempfehlung",
                    'eu': "Drinking Recommendation",
                },
                "Tast": {
                    'sv': "Smaknotis",
                    'de': "Degustation Notiz",
                    'eu': "Tasting Note"
                }
            }
        }
    },

    props: {
        productid: String
    },

    mounted() {
        fetch(`${process.env.NODE_ENV === 'development' ?  process.env.VUE_APP_DEV_SERVER_URL : ''}/api/productsmongo/${this.productid}`)
            .then(res  => res.json())
            .then(data => this.product = data)
            .catch(err => console.log("API ERROR: ", err.message))
    },

    methods: {

        onResize() {

        }
    },

    components: { Product, BlogFeature }
}
</script>