<template>
    <v-footer app  bottom absolute padless style="width: 100%;"  height="50">
        <v-card flat tile class="text-center" style="width: 100%;" >

            <v-divider />

            <v-card-text>

                <v-tooltip top   v-for="(foot, index) in footers" :key="index">
                    <template   v-slot:activator="{ on, attrs }">
                        <v-btn text class="mx-4" icon    :href="foot.href"   :target="foot.target" rel="noopener noreferrer"         v-bind="attrs" v-on="on">
                            <v-icon>{{foot.icon}}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{foot.text}}</span>
                </v-tooltip>

            </v-card-text>

            <v-card-text class="pt-0">
                {{i18n.FootMsg[state.lang]}}
                <!-- Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi quod architecto, nam neque praesentium quia. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Harum unde consequatur voluptas suscipit repellendus tempora nam inventore, error eveniet dicta fugiat. Laudantium labore excepturi vel quas quasi voluptates. Ipsum, amet? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore suscipit reprehenderit exercitationem laboriosam. Harum ea neque sed nemo assumenda. Ut, et quia. Officia quaerat at dolor, dolorum voluptates consequuntur cum. -->
                <!-- Vingården Bechtel ligger i Rheinhessen, ett av Tysklands 13 vindistrikt, i närheten av floden Rhen. Vingården har varit i familjen Bechtels ägor sedan många generationer. Viner som gården genom tiderna har producerat är framförallt vita viner som Riesling, Müller Thurgau, Grauburgunder men också de röda vinena Spätburgunder och Dornfelder. Nu startar nästa generation sin resa till vinbonde och har under detta år producerat sitt första vin som nu säljs på flaska: Sommaräng, en fruktig Riesling. -->
                <!-- Lorem ipsum dolor, sit amet consectetur adipisicing elit. Id, sequi. -->
            </v-card-text>

            <v-divider />

            <v-card-text>
                {{ new Date().getFullYear() }} — <strong>Bechtel</strong>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
import { useStateStore } from '@/store/state.js'

export default {
    data() {
        return {
            state: useStateStore(),

            footers: [
                
                { "text": "info@bechtel.se",                          "target": "",       "href": "mailto:info@bechtel.se",                   "icon": "mdi-email-outline"         }, 
                { "text": "+46721749739",                             "target": "",       "href": "tel:+46721749739",                         "icon": "mdi-phone-outline"         },
                { "text": "@oliver.bechtel",                          "target": "_blank", "href": "https://instagram.com/_u/oliver.bechtel/", "icon": "mdi-instagram"             },
                { "text": "www.bechtel.se",                           "target": "_blank", "href": "https://bechtel.se/",                      "icon": "mdi-web"                   },
                { "text": "Hinterstraße 10, 55278 Eimsheim, Germany", "target": "_blank", "href": "https://goo.gl/maps/rPtpvWuiebm3iPr4A",    "icon": "mdi-map-marker-outline"    },
                { "text": "Impressum",                                "target": "",       "href": "https://old.bechtel.se/de-DE/Impressum",   "icon": "mdi-file-document-outline" },
            ],

            i18n: {
                "FootMsg": {
                    'sv': "Vingården Bechtel ligger i Rheinhessen, ett av Tysklands 13 vindistrikt, i närheten av floden Rhen. Vingården har varit i familjen Bechtels ägor sedan många generationer. Viner som gården genom tiderna har producerat är framförallt vita viner som Riesling, Müller Thurgau, Grauburgunder men också de röda vinena Spätburgunder och Dornfelder. Nu startar nästa generation sin resa till vinbonde och har under detta år producerat sitt första vin som nu säljs på flaska: Sommaräng, en fruktig Riesling.",
                    'de': "Das Weingut Bechtel liegt in der kleinen Weinstadt Eimsheim, mitten in Rheinhessen, einem der 13 deutschen Weinbaugebiete. Das Weingut ist seit vielen Generationen in Familienbesitz. Die Weine, die das Weingut im Laufe der Jahre produziert hat, sind hauptsächlich Weißweine wie Riesling, Müller Thurgau, Silvaner und Grauburgunder, aber auch Rotweine wie Spätburgunder und Dornfelder. Jetzt beginnt die nächste Generation der Familie ihre Reise als Winzer und produzierte dieses Jahr den ersten Wein, der jetzt in Flaschen verkauft wird: Sommarang, ein frischer und fruchtiger trockener Riesling.", 
                    "eu": "The Bechtel winery is located in the small wine town of Eimsheim, in the middle of Rheinhessen, one of the 13 German wine-growing regions. The winery has been family-owned for many generations. The wines that the winery has produced over the years are mainly white wines such as Riesling, Müller Thurgau, Silvaner and Pinot Gris, but also red wines such as Pinot Noir and Dornfelder. Now the next generation of the family starts their journey as winemakers and this year they produced the first wine that is now sold in bottles: Sommarang, a fresh and fruity dry Riesling."
                }
            }
        }
        
    },
}
</script>

<style scoped>

</style>