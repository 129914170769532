<template>
    <v-container>
        <v-row class="mt-10 mb-5" style="box-shadow: 0px 0px 10px #aaa ">
            

                <v-divider />
            
                
        </v-row>
    </v-container>
</template>

<script>
export default {
    
}
</script>