<template>

  <v-app>

      <Navbar />

      <Popup :attr="popup" />

      <v-main>
          <router-view/>
      </v-main>

      <Footer />

  </v-app>

</template>

<script>
import { useStateStore } from '@/store/state.js'
import { useShopStore } from '@/store/shop.js'
import Navbar from '@/components/app/NavbarApp.vue'
import Popup  from '@/components/app/PopupApp.vue' 
import Footer from '@/components/app/FooterApp.vue'

export default {
  name: "App",
  data: () => ({ popup: (localStorage.age !== 'true')}),
  components: { Navbar, Footer, Popup },
  mounted() {
      var state = useStateStore()
      var shop  = useShopStore()

      if(localStorage.getItem('lang')) state.lang = localStorage.getItem('lang')

      state.addRerun(shop.updateAllCurrencys)
      state.addRerun(shop.updateShipping)
      state.addRerun(shop.updateTotalItemPrice)
  }
};
</script>


<!-- <template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
 -->