import { defineStore } from 'pinia'

export const useStateStore = defineStore('state', {
    
    state: () => {
        return { 
            lang: 'sv',
            age:  false,
            langOpt: [
                { text: 'Svenska', i18n: 'Sweden',    icon: require(`@/assets/swedenSq.png`),  icon2: require(`@/assets/sweden.png`), opt: 'sv',   currency: "kr", minimumFractionDigits: 0,  decl: 'https://www.iq.se/tonarsparloren/varfor-ar-alkohol-farligt-for-unga/' },
                { text: 'Deutsch', i18n: 'Germany',   icon: require(`@/assets/germanySq.png`), icon2: require(`@/assets/germany.png`), opt: 'de',  currency: "€",  minimumFractionDigits: 2,  decl: 'https://www.kenn-dein-limit.info/' },
                { text: 'EU', i18n: 'European Union', icon: require(`@/assets/european.png`),  icon2: require(`@/assets/european.png`), opt: 'eu', currency: "€",  minimumFractionDigits: 2,  decl: 'https://www.iq.se/tonarsparloren/en/why-is-alcohol-dangerous-for-young-people/' },
            ],

            _rerun: []
        }
    },
    actions: {
        setLang(lang, rerun=false) {
            this.lang = lang

            if(rerun) this.rerun()
        },

        getLang(get=this.lang) {
            
            for(var i = 0; i < this.langOpt.length; i++) 
                if(get == this.langOpt[i].opt) return this.langOpt[i]
        },

        setAge(age) {
            this.age = age
        },


        addRerun(funk) {

            this._rerun.push(funk)
        },

        rerun(index=-1) {

            console.log("RERUN ")

            if(index < 0)
                for(var i = 0; i < this._rerun.length; i++) 
                    this._rerun[i]()
            else
                this._rerun[index]()
        }
    },

    /* getters: {

    } */
})