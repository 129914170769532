<template>
    <nav>

        <v-snackbar height="0" width="auto" class="ma-0 mt-16 pa-0" right top v-model="snackbar"  color="rgba(0,0,0,0)" elevation="0" timeout="1000">
            <v-alert   dense elevation="5" border="left" type="info" dismissible >
                {{i18n.Empty[state.lang]}}
            </v-alert>               
        </v-snackbar>


        
        <v-app-bar app elevation="4"  clipped-right><!-- hide-on-scroll -->

            <!-- <v-btn text icon depressed class="ma-0 pa-0"><v-icon x-large>mdi-bottle-wine </v-icon></v-btn> -->

            <v-app-bar-nav-icon class="grey--text"  /> <!-- @click="drawer = !drawer" -->

            <v-spacer class=" d-flex d-sm-none    " />

            <v-btn rounded text route to="/">
                <v-toolbar-title>{{i18n.NAME[state.lang]}}</v-toolbar-title>
            </v-btn>

            <!-- width="10" max-height="42"-->
            <!-- <v-img class="mx-3"   max-width="67" src="@/assets/page1_1.jpg"/> -->
            <!-- <v-img class="mx-3"   max-width="67" src="@/assets/Bechtel-LOGGA.png"/> -->
            
            
            <v-spacer />


            <!-- <v-btn
                icon
                @click="e1 = 1"
                >
                <v-icon>mdi-flag</v-icon>
            </v-btn> -->

            <!-- <v-btn text class="rounded-pill d-none d-sm-flex" router to="/blog">
                BLOG < !-- NEWS -- >
            </v-btn> -->

            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text icon v-bind="attrs" v-on="on" class="d-none d-sm-flex">
                        <v-badge avatar bottom overlap bordered>
                            <template v-slot:badge>
                                <v-avatar>
                                    <!-- <img :src="require('@/assets/swedenSq.png')" alt="" /> -->
                                    <img :src="state.getLang().icon2" alt="" />
                                </v-avatar>
                            </template>
                            <v-icon left>mdi-flag</v-icon>
                        </v-badge>
                    </v-btn>
                </template>
                <v-list>
                    <!-- <v-list-item v-for="(link, index) in links" :key="index" router :to="link.route"> -->
                    <v-list-item v-for="(link, i) in state.langOpt" :key="i" @click="changeLang(link.opt)">
                        <v-list-item-icon>
                            <img height="40" :src="link.icon" />
                            <!-- <v-icon>{{link.icon}}</v-icon> -->
                        </v-list-item-icon>
                        <v-list-item-title>
                            {{link.text}}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>




            <!--  

            <v-menu   v-model="basket" :close-on-content-click="false" :nudge-width="200" offset-y >
                <template v-slot:activator="{ on, attrs }"   >
                    <v-btn text icon class="mr-0 " v-bind="attrs" v-on="on"  >
                        < !-- <div class="align-self-center" > -- >
                            <v-badge v-if="shop.totalItems() != 0"  bottom overlap bordered :content="shop.totalItems()"  >
                                <v-icon   left large>mdi-basket-outline</v-icon>
                            </v-badge>
                        
                            <v-icon  @click="snackbar = true"  v-else large >mdi-basket-outline</v-icon>
                        < !-- </div> -- >
                    </v-btn>
                </template>

                <v-card width="260">
                    <v-list v-if="shop.basket.length">
                        < !-- add OVERLAY -- >







                        <v-hover v-slot="{ hover }" v-for="(product, i) in shop.basket" :key="i"  >    < !-- MOVE IN HOVER -- >
 
                            < !--  -- >
                            <v-list-item :style="`${hover ? 'background-color: #fafafa' : ''}`">

                                <v-list-item-avatar>
                                    <v-img src="https://bechtel.se/img/sommarang.3cf59779.jpg" alt="" />
                                </v-list-item-avatar>

                                <v-list-item-content v-if="!hover">< !--  -- >

                                    <v-list-item-title>
                                        {{product.item._i18n[state.lang].name}}
                                    </v-list-item-title>
                                    
                                    <v-list-item-subtitle  >
                                        <span>{{product.count}}st, </span> 
                                        <Price :price="`${shop.getPrice(i)}`" />
                                    </v-list-item-subtitle>   
                                    
                                </v-list-item-content>

                                <v-list-item-content v-else>< !--  -- >

                                    <div class="align-self-center">

                                        <v-btn icon v-if="product.count > 1" @click="shop.increase(i, -1)" >
                                            <v-icon>mdi-minus</v-icon>
                                        </v-btn>
                                        <v-btn icon v-else disabled>
                                            <v-icon>mdi-minus</v-icon>
                                        </v-btn>

                                        <v-btn icon  disabled  >
                                            <span class="grey--text text--darken-0">{{product.count}}</span>
                                        </v-btn>

                                        <v-btn icon @click="shop.increase(i, 1)">
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn >
                                        <v-btn class="ml-4" height="38" icon  @click="shop.removeFromBasket(i)">
                                            <v-icon>mdi-trash-can-outline</v-icon>
                                        </v-btn>

                                    </div>
                                </v-list-item-content>

                            </v-list-item>
                        </v-hover>

                    


                    </v-list>
                    <div v-if="shop.basket.length" class="align-self-center">
                        < !-- <v-btn block disabled color="red">{{shop.totalPrice()}}</v-btn> -- >
                        <v-sheet
                            class="text-center"
                            color="white"
                            elevation="1"
                            height="auto"
                            width="100%"
                            >
                            {{i18n.Total[state.lang]}}: <Price :price="shop.totalPrice(state.lang)" /> + 
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on">mdi-truck</v-icon> 
                                </template>
                                <span>{{i18n.calculated_at_checkout[state.lang]}}</span>
                            </v-tooltip>
                            
                        </v-sheet>

                        < !-- <v-btn color="success" block>{{i18n.Checkout[state.lang]}}</v-btn> -- >
                        <v-dialog
                            v-model="dialog"
                            fullscreen
                            hide-overlay
                            transition="dialog-bottom-transition"
                            >
                            
                            <template v-slot:activator="{ on, attrs }">
                                < !-- <v-btn v-bind="attrs" v-on="on" color="success" block  @click="gotoCheckout()">{{i18n.Checkout[state.lang]}}</v-btn> -- >
                                <v-btn v-bind="not_attrs" v-on="not_on" color="success" block><s>{{i18n.Checkout[state.lang]}}</s></v-btn>
                            </template>

                            <v-card>
                                <v-toolbar dark color="primary">
                                    <v-btn icon @click="dialog=false">
                                        <v-icon >mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>
                                        Basket
                                    </v-toolbar-title>
                                </v-toolbar>

                                <v-container>

                                    <h1 class="title ma-1">Cart</h1>
< !-- :items="shop.basket" -->  <!-- :headers="headers" -- >
                                    <v-data-table 
                                        :headers="headers"
                                        :items="shop.basket"
                                        :items-per-page="-1"
                                        class="elevation-1"
                                        hide-default-footer
                                        
                                        >
                                        < !-- :hide-default-header="shop.basket.length < 2" -- >
                                        < !-- IMG -- >
                                        <template v-slot:[`item.item._imgs`]="{ item }">
                                            <v-avatar class="ma-1">
                                                <img :src="item.item._imgs[0]" alt="">
                                            </v-avatar>
                                        </template>

                                        <template v-slot:[`item.item`]="{ item }">
                                            <span>&emsp;&emsp;{{item.item._i18n[state.lang].name}}</span>
                                        </template>

                                        < !-- ML -- >
                                        <template v-slot:[`item.item.ml`]="{ item }">
                                            <Ml :ml="item.item.ml" /> &emsp;&nbsp;
                                            <br> (<Ml :ml="(item.item.ml * item.count)" />)
                                            < !-- <Ml :ml="item.item.ml" /> (<Ml :ml="(item.item.ml * item.count)" />) -- >
                                        </template>

                                        

                                        <template v-slot:[`item.item.perc`]="{ item }">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn text depressed rounded v-bind="attrs" v-on="on">
                                                        <Percent  :percent="item.item.perc" />
                                                    </v-btn>
                                                </template>
                                                <span><code><Ml :ml="(item.item.perc/10000) * item.item.ml * item.count" :round="true"/></code> ren Alkohol</span>
                                            </v-tooltip>
                                        </template>

                                        <template v-slot:[`item.count`]="{ item }">
                                            <v-btn v-if="item.count > 1" icon class="ma-0 pa-0" @click="shop.increase(item, -1)">
                                                <v-icon>mdi-minus</v-icon>
                                            </v-btn>
                                            <v-btn v-else icon class="ma-0 pa-0" disabled>
                                                <v-icon>mdi-minus</v-icon>
                                            </v-btn>
                                            <v-btn disabled text icon>
                                                <span class="grey--text text--darken-1">{{item.count}}</span>
                                            </v-btn>
                                            <v-btn icon class="ma-0 pa-0" @click="shop.increase(item, 1)">
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                            <v-btn icon class="ma-0 pa-0" @click="shop.removeFromBasket(item)">
                                                <v-icon>mdi-trash-can-outline</v-icon>
                                            </v-btn>
                                        </template>

                                         <template v-slot:[`item.item._i18n.sv.price`]="{ item }">
                                            <Price  :price="item.item._i18n[state.lang].price /* * item.count */" />
                                        </template>

                                        <template v-slot:[`item.total_price`]="{ item }">
                                            <Price :price="item.total_price" /> &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                        </template>

                                        <template v-slot:footer>
                                            <v-divider></v-divider>
                                            <v-data-table
                                                hide-default-header
                                                hide-default-footer
                                                :headers="shipingHeader"
                                                :items="[{icon: 'Shiping Estimated Shiping Time 2-3 days blabla', title: '', time: '',_filler: '', price: ''}]"
                                                :items-per-page="-1"
                                                class="elevation-0"
                                                >

                                                <template v-slot:[`item.title`]="{ }">
                                                    <v-btn text icon class="ml-3">
                                                        <v-icon>mdi-truck</v-icon> 
                                                    </v-btn>
                                                    <span class="ml-16 pl-1">Shiping Estimated Shiping Time 2-3 days blabla </span>
                                                </template>

                                                <template v-slot:[`item.price`]="{ }">
                                                    <Price :price="shop.shippingPrice" /> &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; < !--  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; -- >
                                                </template>

                                            </v-data-table>

                                            <v-divider />
                                            <v-divider />

                                             <v-data-table
                                                hide-default-header
                                                hide-default-footer
                                                :headers="totalHeader"
                                                :items="[{icon: '', title: '',_filler: '', total: ''}]"
                                                :items-per-page="-1"
                                                class="elevation-0"
                                                >

                                                <template v-slot:[`item.icon`]="{ }">
                                                    <v-btn text icon class="ml-3">
                                                        <v-icon>mdi-hexagon-slice-6 </v-icon>
                                                    </v-btn>
                                                </template>

                                                <template v-slot:[`item.title`]="{ }">
                                                    TOTAL:
                                                </template>

                                                <template v-slot:[`item._filler`]="{ }">
                                                    <v-btn disabled text><span v-for="i in 50" :key="i">&emsp;</span></v-btn> < !-- Move all end headers valöues thingys to end and manualy add spaces ++ same on first and second first items  ++ add 1 more filed to productdatatable -- >
                                                </template>

                                                <template v-slot:[`item.total`]="{ }">
                                                    <Price :price="(shop.totalItemPrice + shop.shippingPrice)" /> &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;< !--  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; -- >
                                                </template>

                                            </v-data-table>

                                            <v-divider />

                                        </template>

                                    </v-data-table> 


                                    < !-- <v-btn 
                                        
                                        block
                                        x-large
                                        class="mt-2"
                                        color="success"
                                        @click="makePayment()"
                                        >
                                        submit
                                    </v-btn> -- >

                                </v-container>

                                <v-container v-if="true" class="mt-0">
                                    <form>
                                        <v-text-field v-if="forment"
                                        v-model="form.name"
                                        
                                        :rules="validations[state.lang].name"
                                        
                                        label="Name*"
                                        required
                                        hint="Recipients Full Name"
                                        ></v-text-field>

                                        <v-text-field   v-if="forment"
                                        v-model="form.email"
                                        :rules="validations[state.lang].email"
                                        label="E-mail*"
                                        hint="Email"
                                        required
                                        ></v-text-field>

                                        <v-text-field  v-if="forment || state.lang === 'sv'"
                                        v-model="form.DRN"
                                        :rules="validations[state.lang].drn"
                                        label="DRN Nummer*"
                                        required
                                        ></v-text-field>

                                        <v-text-field v-if="forment"
                                            v-model="form.street"
                                            :rules="validations[state.lang].street"
                                            label="Street*"
                                            required
                                        ></v-text-field>

                                        <v-text-field v-if="forment"
                                            v-model="form.city"
                                            :rules="validations[state.lang].city"
                                            label="Ort*"
                                            required
                                        ></v-text-field>

                                        <v-text-field v-if="forment"
                                            v-model="form.country"
                                            label="Land*"
                                            required
                                            
                                            color="rgba(255, 0, 0, 0)"
                                            loading
                                            readonly
                                        ></v-text-field>

                                        <v-text-field v-if="forment"
                                            v-model="form.phone"
                                            :rules="validations[state.lang].phone"
                                            label="Phone"
                                        ></v-text-field>

                                        <v-row v-if="forment">   < !-- :error-messages="checkboxErrors" -- >
                                            <v-checkbox
                                                class="ml-2"
                                                v-model="form.signup"
                                                
                                                label="Email Signup"
                                                required
                                                @change="$v.checkbox.$touch()"
                                                @blur="$v.checkbox.$touch()"
                                            ></v-checkbox>
                                            <v-checkbox
                                                class="mx-5"
                                                v-model="form.signupMajor"
                                                
                                                label="Only Major News"
                                                required
                                                :disabled="!form.signup"
                                                @change="$v.checkbox.$touch()"
                                                @blur="$v.checkbox.$touch()"
                                            ></v-checkbox>
                                        </v-row>

                                        

                                        <v-btn
                                        
                                        block
                                        x-large
                                        class=" mt-2"
                                        color="success"
                                        @click="makePayment()"
                                        >
                                        {{i18n.ToCheckout[state.lang]}}
                                        </v-btn>
                                        
                                    </form>
                                </v-container>

                                

                            </v-card>

                        </v-dialog>


                    </div>
                    
                </v-card>

                
            </v-menu>
            
            

            < !--  -->





            

        </v-app-bar>

        <v-navigation-drawer    v-model="drawer" absolute temporary  style="position:fixed; top:0; left:0; overflow-y: auto;" >

            <!-- <v-list flat>
                <v-subheader>Bechtel</v-subheader>
                <v-list-item-group v-model="selectedItem">
                    <v-list-item  v-for="(item, i) in items" :key="i"   @click="$vuetify.goTo('#container', gotoOpt)">
                        <v-list-item-icon>
                            <v-icon v-text="'mdi-home'" />
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-content>
                                <v-list-item-title v-text="'hi'" />
                            </v-list-item-content>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list> -->

            <v-list flat>
                <v-subheader><!-- Bechtel v-model="selectedItem"--></v-subheader>
                <v-list-item-group
                    
                    
                >
                    <v-list-item v-for="(item, i) in items" :key="i"  @click="$vuetify.goTo(item.goto, gotoOpt)"  router to="/">
                        <v-list-item-icon>
                            <v-icon v-text="item.icon" />
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.text[state.lang]" />
                        </v-list-item-content>
                    </v-list-item>

                    <!-- <v-list-item v-if="true" router to="/blog">
                        <v-list-item-icon>
                            <v-icon v-text="'mdi-post'" />
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-text="'Blog'" />
                        </v-list-item-content>
                    </v-list-item> -->

                </v-list-item-group>
            </v-list>

        </v-navigation-drawer>


    </nav>
</template>


<script>
import { useStateStore } from '@/store/state.js'
import { useShopStore } from '@/store/shop.js'
import Price from '@/components/tag/PriceTag.vue'
import Ml from '@/components/tag/MlTag.vue'
import Percent from '@/components/tag/PercentTag.vue'
/* import { validationMixin } from 'vuelidate' */
/* import { required, maxLength, email } from 'vuelidate/lib/validators' */

let i18n = {
                "NAME": {
                    'sv': "Vingården Bechtel",
                    'de': "Weingut Bechtel",
                    'eu': "Winery Bechtel",
                },

                "Welcome": {
                    'sv': "Välkommen",
                    'de': "Willkommen",
                    'eu': "Welcome"
                },

                "Exhib": {
                    'sv': "Sommaräng 2021",
                    'de': "Sommaräng 2021",
                    'eu': "Sommaräng 2021"
                },

                "Shop": {
                    'sv': "Shop",
                    'de': "Shop",
                    'eu': "Shop"
                },

                "Empty": {
                    'sv': "Varukorgen är tom",
                    'de': "Der Einkaufskorb ist leer",
                    'eu': "The basket is empty"
                },

                "Checkout": {
                    'sv': "Kassa",
                    'de': "Kasse",
                    'eu': "Checkout"
                },

                "Total": {
                    'sv': "Summa",
                    'de': "Summe",
                    'eu': "Amount"
                },

                "ToCheckout": {
                    'sv': "Till Kassan",
                    'de': "Zur Kasse",
                    'eu': "Go To Checkout",
                },
                "calculated_at_checkout": {
                    "sv": "Fraktkostnader beräknas i kassan",
                    "de": "Die Versandkosten werden an der Kasse berechnet",
                    "eu": "Shipping costs are calculated at checkout"
                }
            }


export default {

    data() {
        return {

            state: useStateStore(),
            shop: useShopStore(),
            drawer: false,
            basket:  false,
            basketdrawer: false,
            snackbar: false,
            dialog: false,

            forment: false,

            /* mixins: [validationMixin ], */

            headers: [
                {text: <button type="button" class="ma-0 ml-3 pa-0 pr-1 v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi mdi-bottle-wine theme--light" style="font-size: 40px;"></i></span></button>, align: 'start', sortable: false, value: 'item._imgs', width: '25px' },
                /* {text: 'artikel namn', align: 'start', sortable: true, value: 'item._i18n.sv.name', }, */
                {text: <span>&emsp;&emsp; artikel namn</span> , align: 'start', sortable: true, value: 'item', width: '250px' },
                {text: 'desc', align: 'start', sortable: false, value: 'item._i18n.sv.text'},
                {text: 'ml', align: 'start', sortable: true, value: 'item.ml',  },
                {text: <span>&emsp;&nbsp;&nbsp;%</span>, align: 'start', sortable: true, value: 'item.perc', },
                {text: 'Count', align: 'start', sortable: true, value: 'count',  },
                {text: 'Price', align: 'start', sortable: true, value: 'item._i18n.sv.price',  },
                {text: <span>price&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>, align: 'end', sortable: true, value: `total_price`, },
            ],

            shipingHeader: [
                {text: 'title' , align: 'start', sortable: false, value: 'title',  },
                {text: 'price', align: 'end', sortable: false, value: 'price', },
            ],

            totalHeader: [
                {text: 'img' , align: 'start', sortable: false, value: 'icon', width: '25px' },
                {text: 'name', align: 'start', sortable: false, value: 'title', width: '250px' },
                {text: '_filler', align: 'center', sortable: false, value: '_filler', width: 'auto' },
                {text: 'total', align: 'end',  sortable: false, value: 'total',  },
            ],

            /* shipingItem: [], */
            
            items: [
                { text: i18n.Welcome, icon: 'mdi-home'   , goto: '#scroll_collage' },
                { text: i18n.Exhib, icon: 'mdi-bottle-wine'  , goto: '#scroll_exhibition'},
                { text: i18n.Shop, icon: 'mdi-basket'  , goto: '#scroll_allProduct'},
                /* { text: i18n.Welcome, icon: 'mdi-flag'  , goto: '#'}, */
            ],

            gotoOpt: {
                duration: 300, 
                offset: 10, 
                easing: 'easeInOutCubic'
            },

            form: {

                name: 'TEST',
                email: 'test@test.test',
                DNR: '012345678',
                street: 'TESTSTREET',
                city: 'TESTCITY',
                country: 'TEST',
                phone: '',
                signup: false,
                signupMajor: true

            },

            i18n: i18n,

            validations: {

                'sv': {
                    name: [
                        v => !!v || 'Namn krävs',
                    ],
                    email: [
                        v => !!v || 'Email krävs',
                        v => /.+@.+\..+/.test(v) || 'Email måste vara giltig',
                    ],
                    drn: [
                        v => !!v || 'Diarienummer krävs',
                        v => v.length === 9 || 'Diarienummer måste vara giltig'
                    ],
                    street: [
                        v => !!v || 'Gata krävs',
                        /* v => !!v.split(' ')[1] || 'Glöm inte husnummer', */
                    ],
                    city: [
                        v => !!v || 'Ort krävs',
                    ],
                    phone: [
                        v => (v[0] == '+' || !v) || '+XX nummer krävs'
                    ]
                },

                'de': {
                    name: [
                        v => !!v || 'Name ist erforderlich',
                    ],
                    email: [
                        v => !!v || 'E-Mail ist erforderlich',
                        v => /.+@.+\..+/.test(v) || 'Email muss gültig sein',
                    ],
                    street: [
                        v => !!v || 'Straße ist erforderlich',
                        /* v => !!v.split(' ')[1] || '', */
                    ],
                    city: [
                        v => !!v || 'Ort ist erforderlich',
                    ],
                    phone: [
                        v => (v[0] == '+' || !v) || '+XX nummer ist erforderlich'
                    ]

                },

                'eu': {
                    name: [
                        v => !!v || 'Name is required',
                    ],
                    email: [
                        v => !!v || 'E-mail is required',
                        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    ],
                    street: [
                        v => !!v || 'Street is required',
                        /* v => !!v.split(' ')[1] || '', */
                    ],
                    city: [
                        v => !!v || 'City is required',
                    ],
                    phone: [
                        v => (v[0] == '+' || !v) || '+XX nummer is required'
                    ]
                }
            },
            
        }
    },

    methods: {
        changeLang(lang) {

            this.state.setLang(lang, true)
            localStorage.setItem('lang', lang)
        },

        gotoCheckout() {

            this.shop.updateShipping()
            this.shop.updateTotalItemPrice()
            this.form.country = this.state.getLang().i18n
        },

        makePayment() {

            this.form.lang = this.state.lang

            var body = {
                items: [],
                info: this.form
            }

            this.shop.basket.forEach((e) => {
                body.items.push({ id: e.item._id, quantity: e.count })
            })

            fetch(`${process.env.NODE_ENV === 'development' ?  process.env.VUE_APP_DEV_SERVER_URL : ''}/api/checkout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
                .then(res => { 
                    if (res.ok) return res.json()
                    return res.json().then(json => Promise.reject(json))
                })
                .then(({ url }) => { 
                    console.log("URL: ", url)
                    window.location = url 
                })
                .catch(e => {
                    console.log(e.error)
                })


           
        }
    },

    /* computed: {

        nameErrors () {
            const errors = []
         // if (!this.validations.name.$dirty) return errors 
         // !this.validations.name.maxLength && errors.push('Name must be at most 10 characters long')
         // !this.validations.name.required && errors.push('Name is required.')
         // return errors
        },

        emailErrors () {
            const errors = []
           //  if (!this.$v.email.$dirty) return errors
           // !this.validations.email && errors.push('Must be valid e-mail')
           // !this.validations.email.required && errors.push('E-mail is required') 
            return errors
        },

        drnErrors() {
            return []
        }
    }, */

    components: { Price, Ml, Percent },

     /* mounted() {
        console.log("Get G: ", this.shop.getItemG(0))
    }, */

    /*methods: {
        getItems() {

            var temp = []
            for(var i = 0; i < this.shop.basket; i++) {

                var t = {}
                
                t['img']  = this.shop.basket[i].item._imgs[0]

                t['name'] = this.shop.basket[i].item._i18n[this.state.lang].name

                /* t['description']  * /

                t['ml'] = this.shop.basket[i].item.ml

                t['total_ml'] = (this.shop.basket[i].item.ml * this.shop.basket[i].count)

                t['percent'] = this.shop.basket[i].item.perc

                t['count'] = this.shop.basket[i].count

                t['price'] = this.shop.basket[i].item.i18n[this.state.lang].price

                t['totla_price'] = (this.shop.basket[i].item.i18n[this.state.lang].price * this.shop.basket[i].count)

                t['_item'] = this.shop.basket[i].item

                temp.push(t)
            }
            this.newItems = temp
            return temp
        }
    }, */
}
</script>